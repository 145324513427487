(function () {
	angular
		.module('fca.commonFilters')
		.directive('fcaFleetDocumentsFilters', fcaFleetDocumentsFilters);

	function fcaFleetDocumentsFilters() {
		return {
			restrict: 'A',
			scope: true,
			controllerAs: '$ctrl',
			controller: fleetDocumentsFiltersController
		};

		function fleetDocumentsFiltersController() {
			'ngInject';
			const $ctrl = this;

			$ctrl.filteredCategory = 'all-categories';

			$ctrl.resetBrandFilter = () => {
				$ctrl.filteredBrand = 'all-brands';
				$ctrl.currentCategoryBrands = [];
			}

			$ctrl.resetBrandFilter();

			$ctrl.filterByCategory = () => {
				$ctrl.resetBrandFilter();

				document.querySelectorAll('.doc-list-category').forEach((category) => {
					category.style.display = 'block';
					$ctrl.resetDocuments(category);

					// show or hide category based on category filter
					$ctrl.toggleCategory(category);

					// find specific brand options for selected category
					$ctrl.findBrandOptions(category);
				});

				dataLayer.push({
					eventCategory: "documentslibrary",
					eventAction: window.location.href,
					event: "gaevent",
					value: $ctrl.filteredCategory,
					id: "category-dropdown",
				});
			}

			$ctrl.filterByBrand = () => {
				document.querySelectorAll('.doc-list-category').forEach((category) => {
					$ctrl.toggleCategory(category);

					if (category.id === $ctrl.filteredCategory || $ctrl.filteredCategory === 'all-categories') {
						// set all documents to display before filtering
						$ctrl.resetDocuments(category);

						category.querySelectorAll('.doc-list-item').forEach((document) => {
							if ($ctrl.filteredBrand === 'all-brands') {
								document.style.display = 'flex';
							} else {
								document.getAttribute('data-brand-document') === $ctrl.filteredBrand ?
									document.style.display = 'flex'
									: document.style.display = 'none';
							}
						});
					}
				});

				// remove categories that have no documents
				if ($ctrl.filteredCategory === 'all-categories') {
					$ctrl.resetCategories();
				}

				dataLayer.push({
					eventCategory: "documentslibrary",
					eventAction: window.location.href,
					event: "gaevent",
					value: $ctrl.filteredBrand,
					id: "brand-dropdown",
				});
			}

			$ctrl.toggleCategory = (category) => {
				if ($ctrl.filteredCategory === 'all-categories') {
					category.style.display = 'block';
				} else if (category.id !== $ctrl.filteredCategory) {
					category.style.display = 'none';
				}
			}

			$ctrl.findBrandOptions = (category) => {
				if (category.style.display === 'block') {
					category.querySelectorAll('.doc-list-item').forEach((item) => {
						const brand = item.getAttribute('data-brand-document');

						if (!$ctrl.currentCategoryBrands.includes(brand)) {
							$ctrl.currentCategoryBrands.push(item.getAttribute('data-brand-document'));
						}
					})
				}
			}

			$ctrl.resetDocuments = (category) => {
				category.querySelectorAll('.doc-list-item').forEach((document) => {
					document.style.display = 'flex';
				});
			}

			$ctrl.resetCategories = () => {
				const domCategories = document.querySelectorAll('.doc-list-category');

				domCategories.forEach((category) => {
					let isContainingDocument;
					category.querySelectorAll('.doc-list-item').forEach((document) => {
						if (document.style.display === 'flex') {
							isContainingDocument = true;
						}
					});
					isContainingDocument ? category.style.display = 'block' : category.style.display = 'none';
				});
			}
		}
	}
})();
